<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" [disabled]="!form.valid" (click)="save()">
        Save
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" *ngIf="form">
    <ion-list>
      <ion-item>
        <ion-label position="floating">Title</ion-label>
        <ion-input type="text" formControlName="title"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">Code</ion-label>
        <ion-input type="text" formControlName="code"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">Currency Symbol</ion-label>
        <ion-input type="text" formControlName="currency_symbol"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">Rate</ion-label>
        <ion-input type="text" formControlName="rate"></ion-input>
      </ion-item>
      
      <ion-item>
        <ion-label position="floating">Sort Order</ion-label>
        <ion-input type="text" formControlName="sort_order"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Active?</ion-label>
        <ion-toggle slot="end" formControlName="status"></ion-toggle>
      </ion-item>
  
    </ion-list>
  </form>

</ion-content>
