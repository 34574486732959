
<div class="update-alert" dir="ltr">
  <span class="wrapper">
      <span class="title">A new version is available</span>
      <span class="btn-refresh">
          &nbsp;
          <span class="ag a8k" tabindex="0" role="link" tappable (click)="refresh()">
            Refresh
          </span>
      </span>
  </span>
  <div tabindex="0" role="button" class="btn-close" tappable (click)="close()">
      <ion-icon name="close"></ion-icon>
  </div>
</div>