<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="webhook-list" (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end">
      <ion-button clear color="primary" 
        [disabled]="!form.valid"
        (click)="save()">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" *ngIf="form">
    <ion-list>
        <ion-item>
          <ion-label position="floating">Event</ion-label>
          <ion-select formControlName="event" interface="popover">
            <ion-select-option>Company Profile Created</ion-select-option>
            <ion-select-option>Request Activity Added</ion-select-option>
            <ion-select-option>Transfer Created</ion-select-option>
            <ion-select-option>Transfer Updated</ion-select-option>
            <ion-select-option>Fulltimer Created</ion-select-option>
            <ion-select-option>Fulltimer Updated</ion-select-option>
            <ion-select-option>Request Created</ion-select-option>
            <ion-select-option>Request Updated</ion-select-option>
            <ion-select-option>Suggestion Created</ion-select-option>
            <ion-select-option>Suggestion Updated</ion-select-option>
            <ion-select-option>Transfer Marked As Payment Received</ion-select-option>
            <ion-select-option>Transfer Locked</ion-select-option>
            <ion-select-option>Candidate Transfer Paid</ion-select-option>
            <ion-select-option>Candidate Profile Created</ion-select-option>
            <ion-select-option>Candidate Profile Updated</ion-select-option>
            <ion-select-option>Candidate Invitation Accepted</ion-select-option>
            <ion-select-option>Candidate Invitation Rejected</ion-select-option>
            <ion-select-option>Candidate Invited</ion-select-option>
            <ion-select-option>New Wallet Entry</ion-select-option>
            <ion-select-option>Paid By Wallet</ion-select-option>
            <ion-select-option>Expense Added</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Endpoint</ion-label>
          <ion-input type="url" formControlName="endpoint"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Method</ion-label>
          <ion-select formControlName="method" interface="popover">
            <ion-select-option>GET</ion-select-option>
            <ion-select-option>POST</ion-select-option>
            <ion-select-option>PUT</ion-select-option>
            <ion-select-option>PATCH</ion-select-option>
            <ion-select-option>DELETE</ion-select-option>
          </ion-select>
        </ion-item> 
    </ion-list>
  </form>
</ion-content>
