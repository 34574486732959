<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="major-list" (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end">
      <ion-button clear color="primary" 
        [disabled]="!form.valid"
        (click)="save()">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" *ngIf="form">
    <ion-list>
        <ion-item>
          <ion-label position="floating">Name</ion-label>
          <ion-input type="text" formControlName="name_en"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Name - Arabic</ion-label>
          <ion-input type="text" formControlName="name_ar"></ion-input>
        </ion-item>
    </ion-list>
  </form>
</ion-content>
