<ion-app>
  <ion-split-pane contentId="main-content">

    <ion-menu contentId="main-content" type="overlay" [disabled]="!authService.isLogin || !this.authService.navEnable">
      <ion-content>

        <ion-list id="inbox-list">

          <ion-list-header>
             Welcome,
          </ion-list-header>
          <ion-note>{{ authService.name }}!</ion-note>

          <ion-button fill="clear" class="btn-theme" (click)="changeTheme()">
            <ion-icon *ngIf="authService.theme == 'night'" slot="icon-only" name="moon-outline"></ion-icon>
            <ion-icon *ngIf="authService.theme != 'night'" slot="icon-only" name="partly-sunny-outline"></ion-icon>
          </ion-button>

          <!-- Main Action Points -->
          <ion-item-group>
            <ion-item-divider>
              <ion-label>Action Points</ion-label>
            </ion-item-divider>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'/dashboard'">
                <div tabindex="0"></div>
                <ion-icon name="stats-chart-outline" slot="start"></ion-icon>
                <ion-label>
                  Summary
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'/dashboard-quicks'">
                <div tabindex="0"></div>
                <ion-icon name="stats-chart-outline" slot="start"></ion-icon>
                <ion-label>
                  Payment Summary
                </ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'transfer-list'">
                <div tabindex="0"></div>
                <ion-icon name="globe-outline" slot="start"></ion-icon>
                <ion-label>Transfers</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'suspicious-transfer-list'">
                <div tabindex="0"></div>
                <ion-icon name="globe-outline" slot="start"></ion-icon>
                <ion-label class="ion-text-wrap">Transfers with wrong hourly</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-item-group>

          <ion-item-group>
            <ion-item-divider>
              <ion-label>Call center</ion-label>
            </ion-item-divider>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'/voice-mail-list'">
                <div tabindex="0"></div>
                <ion-icon name="globe-outline" slot="start"></ion-icon>
                <ion-label>
                  Voice mails
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-item-group>    

          <ion-item-group>
            <ion-item-divider>
              <ion-label>Marketing</ion-label>
            </ion-item-divider>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'/campaign-list'">
                <div tabindex="0"></div>
                <ion-icon name="globe-outline" slot="start"></ion-icon>
                <ion-label>
                  Social Campaign
                </ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'/email-campaign-list'">
                <div tabindex="0"></div>
                <ion-icon name="mail-outline" slot="start"></ion-icon>
                <ion-label>
                  Email Campaign
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-item-group>

          <!-- Process Payments -->
          <ion-item-group>
            <ion-item-divider>
              <ion-label>Process Payments</ion-label>
            </ion-item-divider>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'transfer-bank-advice'">
                <div tabindex="0"></div>
                <ion-icon name="globe-outline" slot="start"></ion-icon>
                <ion-label class="ion-text-wrap">
                   Bank Advice Files 
                </ion-label> 
              </ion-item>
            </ion-menu-toggle>
            
            <ion-menu-toggle auto-hide="false">
              <!-- *ngIf="totalPayableCandidate > 0" -->
              <ion-item lines="none" [routerLink]="'payable-candidates'">
                <div tabindex="0"></div>
                <ion-icon name="people-outline" slot="start"></ion-icon>
                <ion-label class="ion-text-wrap">
                  Payable Candidates
                </ion-label>

                <ion-badge slot="end" *ngIf="totalPayableCandidate">
                  {{ totalPayableCandidate }}
                </ion-badge>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'candidate-payment-search'">
                <div tabindex="0"></div>
                <ion-icon name="search-outline" slot="start"></ion-icon>
                <ion-label>
                  Payment Search
                </ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'transfer-file-list'">
                <div tabindex="0"></div>
                <ion-icon name="folder-open-outline" slot="start"></ion-icon>
                <ion-label>
                  Transfer Files
                </ion-label>
              </ion-item>
            </ion-menu-toggle>

          </ion-item-group>

          <!-- Accounts Management -->

          <ion-item-group>
            <ion-item-divider>
              <ion-label>Accounts </ion-label>
            </ion-item-divider>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'admin-list'">
                <div tabindex="0"></div>
                <ion-icon name="person-circle-outline" slot="start"></ion-icon>
                <ion-label>
                  Admin Accounts
                </ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'company-list'">
                <div tabindex="0"></div>
                <ion-icon name="briefcase-outline" slot="start"></ion-icon>
                <ion-label>
                  Companies
                </ion-label>
              </ion-item>
              <ion-item lines="none" [routerLink]="'company-contact-list'">
                <div tabindex="0"></div>
                <ion-icon name="briefcase-outline" slot="start"></ion-icon>
                <ion-label>
                  Companies Contacts
                </ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'staff-list'">
                <div tabindex="0"></div>
                <ion-icon name="people-outline" slot="start"></ion-icon>
                <ion-label>Staff</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'inspector-list'">
                <div tabindex="0"></div>
                <ion-icon name="people-outline" slot="start"></ion-icon>
                <ion-label>Inspector</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'candidate-list'">
                <div tabindex="0"></div>
                <ion-icon name="people-outline" slot="start"></ion-icon>
                <ion-label>Candidate</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'fulltimer-list'">
                <div tabindex="0"></div>
                <ion-icon name="people-outline" slot="start"></ion-icon>
                <ion-label>Fulltimer</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-item-group>

          <ion-item-group>
            <ion-item-divider>
              <ion-label>Report </ion-label>
            </ion-item-divider>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'daily-standup-answer-list'">
                <div tabindex="0"></div>
                <ion-icon name="chatbox-outline" slot="start"></ion-icon>
                <ion-label class="ion-text-wrap">Staff Daily Stand-up Answer</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'staff-work-session-list'">
                <div tabindex="0"></div>
                <ion-icon name="chatbox-outline" slot="start"></ion-icon>
                <ion-label class="ion-text-wrap">Staff Work Session</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'staff-expense-list'">
                <div tabindex="0"></div>
                <ion-icon name="cash-outline" slot="start"></ion-icon>
                <ion-label>Staff Expenses</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" routerLink="staff-leave-list">
                <div tabindex="0"></div>
                <ion-icon name="chatbox-outline" slot="start"></ion-icon>
                <ion-label class="ion-text-wrap">Staff Leave Request</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'import'">
                <div tabindex="0"></div>
                <ion-icon name="cash-outline" slot="start"></ion-icon>
                <ion-label>Import Excel</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'assigned-candidate'">
                <div tabindex="0"></div>
                <ion-icon name="people-outline" slot="start"></ion-icon>
                <ion-label>Assigned Candidate</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" routerLink="request-list">
                <div tabindex="0"></div>
                <ion-icon name="people-outline" slot="start"></ion-icon>
                <ion-label>Request List</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" routerLink="story-list">
                <div tabindex="0"></div>
                <ion-icon name="people-outline" slot="start"></ion-icon>
                <ion-label>Story List</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-item-group>

          <ion-item-group>
            <ion-item-divider>
              <ion-label>Entities </ion-label>
            </ion-item-divider>


            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'daily-standup-question-list'">
                <div tabindex="0"></div>
                <ion-icon name="chatbox-outline" slot="start"></ion-icon>
                <ion-label class="ion-text-wrap">Daily Stand-up Question</ion-label>
              </ion-item>
            </ion-menu-toggle>


            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" routerLink="expense-list">
                <div tabindex="0"></div>
                <ion-icon name="cash-outline" slot="start"></ion-icon>
                <ion-label>Office Expenses</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'tag-list'">
                <div tabindex="0"></div>
                <ion-icon name="cash-outline" slot="start"></ion-icon>
                <ion-label>Candidate Tags</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'bank-list'">
                <div tabindex="0"></div>
                <ion-icon name="cash-outline" slot="start"></ion-icon>
                <ion-label>Banks</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'university-list'">
                <div tabindex="0"></div>
                <ion-icon name="school-outline" slot="start"></ion-icon>
                <ion-label>Universities</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'major-list'">
                <div tabindex="0"></div>
                <ion-icon name="school-outline" slot="start"></ion-icon>
                <ion-label>Majors</ion-label>
              </ion-item>
            </ion-menu-toggle>


            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'degree-list'">
                <div tabindex="0"></div>
                <ion-icon name="school-outline" slot="start"></ion-icon>
                <ion-label>Degrees</ion-label>
              </ion-item>
            </ion-menu-toggle>


            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'degree-group-list'">
                <div tabindex="0"></div>
                <ion-icon name="school-outline" slot="start"></ion-icon>
                <ion-label>Degree Groups</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" [routerLink]="'country-list'">
                <div tabindex="0"></div>
                <ion-icon name="paper-plane-outline" slot="start"></ion-icon>
                <ion-label>Countries</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-item-group>

          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" routerLink="/stats">
              <div tabindex="0"></div>
              <ion-icon name="bar-chart-outline" slot="start"></ion-icon>
              <ion-label>Statistics</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" routerLink="/request-checklist">
              <div tabindex="0"></div>
              <ion-icon name="list" slot="start"></ion-icon>
              <ion-label>Request Checklist</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" routerLink="/log-date-list">
              <div tabindex="0"></div>
              <ion-icon name="list" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">Candidate Working Hours</ion-label>
            </ion-item>
          </ion-menu-toggle>


          <ion-item-group>
            <ion-item-divider>
              <ion-label>Settings </ion-label>
            </ion-item-divider>

            <ion-item lines="none" routerLink="/currency-list">
              <div tabindex="0"></div>
              <ion-icon name="cash-outline" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">Currencies</ion-label>
            </ion-item> 
            
            <ion-item lines="none" routerLink="/blocked-ip-list">
              <div tabindex="0"></div>
              <ion-icon name="cog-outline" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">Blocked IPs</ion-label>
            </ion-item> 

            <ion-item lines="none" routerLink="/setting">
              <div tabindex="0"></div>
              <ion-icon name="cog-outline" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">App Settings</ion-label>
            </ion-item>
          
            <ion-item lines="none" routerLink="/webhook-list">
              <div tabindex="0"></div>
              <ion-icon name="cog-outline" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">Webhooks</ion-label>
            </ion-item>
            
            
          </ion-item-group>

          <ion-item-group>
            <ion-item-divider>
              <ion-label>Discounts </ion-label>
            </ion-item-divider>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" routerLink="/discount-category-list">
                <div tabindex="0"></div>
                <ion-icon name="cart-outline" slot="start"></ion-icon>
                <ion-label>Discount Categories</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-item-group>
          
          <ion-item-group>
            <ion-item-divider>
              <ion-label>Permission </ion-label>
            </ion-item-divider>

            <ion-menu-toggle auto-hide="false">
              <ion-item lines="none" routerLink="/permission-section-list">
                <div tabindex="0"></div>
                <ion-icon name="log-out-outline" slot="start"></ion-icon>
                <ion-label>Permission Section</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-item-group>

          <ion-item-group>
            <ion-item-divider>
              <ion-label>Xero </ion-label>
            </ion-item-divider>

            <ion-item lines="none" routerLink="/bank-transactions">
              <div tabindex="0"></div>
              <ion-icon name="cash-outline" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">Bank Transactions</ion-label>
            </ion-item> 
          </ion-item-group>

          <ion-item-group>
            <ion-item-divider>
              <ion-label>Security </ion-label>
            </ion-item-divider>

            <ion-item lines="none" routerLink="/mail-log-list">
              <div tabindex="0"></div>
              <ion-icon name="mail-outline" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">Mail Logs</ion-label>
            </ion-item> 
          </ion-item-group>

          <ion-item-group>
            <ion-item-divider>
              <ion-label>Personal </ion-label>
            </ion-item-divider>

            <ion-menu-toggle auto-hide="false">
              <ion-item tappable lines="none" class="ion-activatable ion-focusable" (click)="authService.logout()">
                <div tabindex="0"></div>
                <ion-icon name="log-out-outline" slot="start"></ion-icon>
                <ion-label>Logout</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-item-group>

        </ion-list>

      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>
</ion-app>

<!-- service worker update message -->

<update-alert  (onRefresh)="onUpdateAlertRefresh()" *ngIf="updatesAvailable" (onClose)="onUpdateAlertClose()"></update-alert>
