<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="close()"></ion-back-button>
    </ion-buttons>
    <ion-title>Evaluation Question</ion-title>
    <ion-buttons slot="end" *ngIf="form">
      <ion-button [disabled]="!form.valid" (click)="save($event)">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="form">
    <form [formGroup]="form">
      <ion-list>
        <ion-item>
          <ion-label position="floating">Question</ion-label>
          <ion-input type="text" formControlName="name"></ion-input>
        </ion-item>
        <ion-item *ngFor="let chk of checkboxList">
          <ion-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="chk.selected" slot="start"></ion-checkbox>
          <ion-label>{{chk.name}}</ion-label>
        </ion-item>
      </ion-list>
    </form>
  </div>
</ion-content>
