<!------------------ text-list -------------------->

<div class="text-list" *ngIf="loading && type == 'text-list'">
  <ion-list>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
  </ion-list>
</div>

<!------------------ review-list -------------------->

<div class="review-list" *ngIf="loading && type == 'review-list'">

  <ion-item>
    <div tabindex="0"></div>
    <ion-avatar slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-avatar>
    <ion-label>
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <ion-item>
    <div tabindex="0"></div>
    <ion-avatar slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-avatar>
    <ion-label>
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <ion-item>
    <div tabindex="0"></div>
    <ion-avatar slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-avatar>
    <ion-label>
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</div>

<!------------------ university-list -------------------->

<div class="university-view" *ngIf="loading && type == 'university-view'">

  <ion-item>
    <div tabindex="0"></div>
    <ion-label>
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <ion-item>
    <div tabindex="0"></div>
    <ion-label>
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <ion-item>
    <div tabindex="0"></div>
    <ion-label>
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item> 
</div>

<!------------------ transfer-list -------------------->

<div class="transfer-list" *ngIf="loading && type == 'transfer-list'">
  <ion-item>
    <div tabindex="0"></div>
    <ion-grid>
      <ion-row> 
        <ion-col class="ion-text-center">
          <ion-skeleton-text class="title" animated></ion-skeleton-text>
          <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
          <b><ion-skeleton-text class="sub-title" animated></ion-skeleton-text></b>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" class='status'>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p>  
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p> 
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p> 
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p> 
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
  
  <ion-item>
    <div tabindex="0"></div>
    <ion-grid>
      <ion-row> 
        <ion-col class="ion-text-center">
          <ion-skeleton-text class="title" animated></ion-skeleton-text>
          <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
          <b><ion-skeleton-text class="sub-title" animated></ion-skeleton-text></b>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" class='status'>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p>  
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p> 
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p> 
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p> 
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
  
  <ion-item>
    <div tabindex="0"></div>
    <ion-grid>
      <ion-row> 
        <ion-col class="ion-text-center">
          <ion-skeleton-text class="title" animated></ion-skeleton-text>
          <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
          <b><ion-skeleton-text class="sub-title" animated></ion-skeleton-text></b>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" class='status'>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p>  
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p> 
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p> 
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="12" size-sm="6">
          <p><ion-skeleton-text animated></ion-skeleton-text></p> 
          <ion-skeleton-text class="detail" animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
</div>

<!------------------ transfer -------------------->

<div class="transfer" *ngIf="loading && type == 'transfer'">

  <ion-toolbar class="ion-padding ion-text-center">
    <ion-title>
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-title>

    <div>
      <ion-skeleton-text animated></ion-skeleton-text>
    </div>

  </ion-toolbar>

  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col size="6" size-md="3">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="6" size-md="3">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="6" size-md="3">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="6" size-md="3">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="6" size-md="3">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="6" size-md="3">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
        <ion-col size="6" size-md="3">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</div>

<!------------------ staff-form -------------------->

<div class="staff-form" *ngIf="loading && type == 'staff-form'">

  <ion-item>
    <div tabindex="0"></div>
    <ion-label>
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <ion-item>
    <div tabindex="0"></div>
    <ion-label>
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <ion-item>
    <div tabindex="0"></div>
    <ion-label>
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item> 
</div>

<!------------------ transfer-detail -------------------->

<div class="transfer-detail" *ngIf="loading && type == 'transfer-detail'">
  <ion-item>
    <div tabindex="0"></div>
    <ion-label class="label label-ios" >
      <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
      <ion-skeleton-text animated></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-label>
  </ion-item>

  <div class="ion-text-center detail">
    <h1><ion-skeleton-text animated></ion-skeleton-text></h1>
    <h2><ion-skeleton-text animated></ion-skeleton-text></h2> 
  </div>
</div>

<div class="stats-detail" *ngIf="loading && type == 'stats-detail'">
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
  <ion-row><ion-col><ion-skeleton-text animated></ion-skeleton-text></ion-col></ion-row>
</div>
