export class Admin{
    admin_id: number;
    admin_name: string;
    admin_email: string;
    admin_password_hash: string;
    admin_status: number;
    admin_limited_access: any;
    admin_created_at: string;
    admin_updated_at: string;
}
