<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="close($event)"></ion-back-button>
    </ion-buttons>
    <ion-title *ngIf="model && model.staff">{{model.staff.staff_name}}'s Answers</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item *ngFor="let answer of data">
      <ion-label>
        <h3>{{answer.question}}</h3>
        <h4 class="ion-text-wrap">{{answer.answer}}</h4>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
