<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" [disabled]="!form || !form.valid || saving" (click)="save()">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" *ngIf="form">
    <ion-list>
      <ion-item>
        <ion-label position="floating">Title</ion-label>
        <ion-input type="text" formControlName="title"></ion-input>
      </ion-item>
      
      <ion-item>
        <ion-label position="floating">Type</ion-label>
        <ion-input type="text" placeholder="e.g., Salary, Light bill" formControlName="type"></ion-input>
      </ion-item>
      
      <ion-item>
        <ion-label position="floating">Detail</ion-label>
        <ion-textarea formControlName="detail"></ion-textarea>
      </ion-item>
      
      <ion-item>
        <ion-label position="floating">Amount (KWD)</ion-label>
        <ion-input type="number" formControlName="amount"></ion-input>
      </ion-item>

      <h6>Transaction  datetime</h6>

      <ion-item>
        
        <ion-datetime pickerFormat="MMM DD, YYYY hh:mm A" displayFormat="MMM DD, YYYY hh:mm A" formControlName="transaction_datetime"></ion-datetime>
        <small>Default will be current time</small>
      </ion-item>

    </ion-list>
  </form>
</ion-content>
