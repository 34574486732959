<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end">
      <ion-button clear color="primary" 
        [disabled]="!form.valid"
        (click)="save()">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" *ngIf="form">
    <ion-list>

        <app-image-upload
                label="Company Logo" icon="person-outline" prefix="logo"
                formControlName="logo"
        ></app-image-upload>

        <ion-item>
          <ion-label position="floating">Name</ion-label>
          <ion-input type="text" formControlName="name"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Common Name en</ion-label>
          <ion-input type="text" formControlName="common_name_en"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Common Name ar</ion-label>
          <ion-input type="text" formControlName="common_name_ar"></ion-input>
        </ion-item>

        <ion-item *ngIf="!this.isSubCompany">
          <ion-label position="floating">Password</ion-label>
          <ion-input type="text" formControlName="password"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Description - English</ion-label>
          <ckeditor [config]="editorConfig" #ckeditor [editor]="Editor" 
            (ready)="onEditorReady()" (change)="onChange($event)">
          </ckeditor>
          <!-- <ion-textarea formControlName="description_en"></ion-textarea> -->
        </ion-item>

        <ion-item>
          <ion-label position="floating">Description - Arabic</ion-label>
          <ckeditor [config]="editorConfig" #ckeditor_ar [editor]="Editor" 
            (ready)="onArabicEditorReady()" (change)="onArabicEditorChange($event)">
          </ckeditor>
          <!-- <ion-textarea formControlName="description_ar"></ion-textarea> -->
        </ion-item>

        <ion-item>
          <ion-label position="floating">Website</ion-label>
          <ion-input type="text" formControlName="website"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Hourly Rate</ion-label>
          <ion-input type="text" formControlName="hourly_rate"></ion-input>
        </ion-item>
        
        <ion-item>
          <ion-label position="floating">Bonus Commission(%)</ion-label>
          <ion-input type="text" formControlName="bonus_commission"></ion-input>
        </ion-item>
        
        <ion-item *ngIf="!isSubCompany">
          <ion-label position="floating">Email</ion-label>
          <ion-input type="text" formControlName="email"></ion-input>
        </ion-item>

        <ion-item *ngIf="!isSubCompany">
          <div tabindex="0"></div>
          <ion-label>Approved to hire?</ion-label>
          <ion-toggle slot="end" formControlName="approved_to_hire"></ion-toggle>
        </ion-item>

    </ion-list>
  </form>
</ion-content>
