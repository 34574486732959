<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end">
      <ion-button clear color="primary" [disabled]="!form || !form.valid" (click)="save()">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" *ngIf="form">
    <ion-list>

      <div class="logo-wrapper ion-text-center" *ngIf="form">

        <div *ngIf="!progress">
          <img tappable (click)="triggerUpdatePhoto($event)"
            *ngIf="form.controls['logo'].value && form.controls['logo'].value.length > 0"
            src="{{ form.controls['logo_path'].value }}"><br />

          <div tappable (click)="triggerUpdatePhoto($event)" class="no-logo"
            *ngIf="!form.controls['logo'].value || form.controls['logo'].value.length == 0">
            <ion-icon src="assets/images/placeholder.png"></ion-icon>
          </div>

          <ion-button color="dark" id="upload-pic" class="change-logo ion-text-center" #btnChangePhoto
            (click)="updatePhoto($event);">
            <ion-icon src="assets/images/md-cloud-upload.svg"></ion-icon>&nbsp;
            <span *ngIf="model.staff_photo">Replace Profile Photo</span>
            <span *ngIf="!model.staff_photo">Select Profile Photo</span>
          </ion-button>
        </div>

        <input class="upload" type='file' #fileInput (change)="browserUpload($event)" accept=".jpg,.jpeg,.png">
      </div>

      <div class="progress-wrapper" *ngIf="progress">
        <div class="progress-box">

          <h4>Uploading… <span>{{ progress + '%' }}</span></h4>

          <div class="progress-bar">
            <div class="progress" [style.width]="progress + '%'"></div>
          </div>

          <ion-icon *ngIf="currentTarget" tappable src="assets/images/close.svg" (click)="cancelUpload()">
          </ion-icon>
        </div>
      </div>

      <ion-item>
        <ion-label position="floating">Name</ion-label>
        <ion-input type="text" formControlName="name"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Email</ion-label>
        <ion-input type="text" formControlName="email"></ion-input>
      </ion-item>

      <ion-item class="field-password" *ngIf="!model.staff_id">
        <ion-label position="floating">Password</ion-label>
        <ion-input type="{{ type }}" formControlName="password"></ion-input>

        <ion-button class="btn-password" fill="clear" (click)="togglePasswordVisibility()">
          <ion-icon *ngIf="type == 'password'" name="eye"></ion-icon>
          <ion-icon *ngIf="type != 'password'" name="eye-off"></ion-icon>
        </ion-button>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Gmail Username</ion-label>
        <ion-input type="text" formControlName="gmail_username"></ion-input>
      </ion-item>

      <ion-item class="field-password">
        <ion-label position="floating">Gmail Password</ion-label>
        <ion-input type="{{ type }}" formControlName="gmail_password"></ion-input>

        <ion-button class="btn-password" fill="clear" (click)="togglePasswordVisibility()">
          <ion-icon *ngIf="type == 'password'" name="eye"></ion-icon>
          <ion-icon *ngIf="type != 'password'" name="eye-off"></ion-icon>
        </ion-button>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Role</ion-label>
        <ion-select formControlName="role">
          <ion-select-option value="1">Management</ion-select-option>
          <ion-select-option value="2">Engineering</ion-select-option>
          <ion-select-option value="3">Sales</ion-select-option>
          <ion-select-option value="4">Business development/ Govt & PR</ion-select-option>
          <ion-select-option value="5">Marketing and Design</ion-select-option>
          <ion-select-option value="6">Recruitment</ion-select-option>
          <ion-select-option value="7">Finance</ion-select-option>
          <ion-select-option value="8">HR</ion-select-option>
          <ion-select-option value="9">Customer success</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Job Title</ion-label>
        <ion-input type="text" formControlName="job_title"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Salary per month</ion-label>
        <ion-input type="number" formControlName="salary"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Salary Currency</ion-label>
        <ion-input type="text" formControlName="salary_currency"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Hours per day</ion-label>
        <ion-input type="number" formControlName="hours_per_day"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Week start day</ion-label>
        <ion-select formControlName="week_start_day">
          <ion-select-option value="1">Monday</ion-select-option>
          <ion-select-option value="2">Tuesday</ion-select-option>
          <ion-select-option value="3">Wednesday</ion-select-option>
          <ion-select-option value="4">Thursday</ion-select-option>
          <ion-select-option value="5">Friday</ion-select-option>
          <ion-select-option value="6">Saturday</ion-select-option>
          <ion-select-option value="7">Sunday</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label position="floating">No of days per week </ion-label>
        <ion-input type="number" formControlName="work_days"></ion-input>
      </ion-item>

      <ion-item>
        <div tabindex="0"></div>
        <ion-label>Receive notification?</ion-label>
        <ion-toggle (ionChange)="onchange($event)" formControlName="staff_notification"></ion-toggle>
      </ion-item>

      <ng-container *ngIf="form && form.value.staff_notification">
        <ion-item>
          <div tabindex="0"></div>
          <ion-label>Notifications</ion-label>
          <ion-select multiple formControlName="permissions">
            <ion-select-option value="transfer-failed">Transfer failed </ion-select-option>
            <ion-select-option value="morning-report">Morning report </ion-select-option>
            <ion-select-option value="daily-attendance-notification">
              Daily attendance notification
            </ion-select-option>
            <ion-select-option value="request-updates">
              Request updates
            </ion-select-option>
            <ion-select-option value="new-requests">New requests </ion-select-option>
          </ion-select>
        </ion-item>  
      </ng-container>

    </ion-list>
  </form>

  <loading-modal type="staff-form" [loading]="!form"></loading-modal>
</ion-content>