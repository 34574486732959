<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end" *ngIf="!authService.admin_limited_access">
      <ion-button clear color="primary" [disabled]="!form.valid" (click)="save()">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" *ngIf="form">
    <ion-list>

      <ion-item>
        <ion-label position="floating">Primary Email</ion-label>
        <ion-input type="text" formControlName="email" (ionChange)="checkEmailAvailable($event)"></ion-input>
      </ion-item>

      <ion-item *ngIf="contact">
        <div tabindex="0"></div>

        <ion-label>
          <h2>Found contact with given email </h2>
          <h4>{{ contact.contact_name }}</h4>

          <!--
          <h5 *ngIf="contact.company">
            {{ contact.company.company_common_name_en? contact.company.company_common_name_en: contact.company.company_name }}
          </h5>-->

          <hr/>

          <ion-item>
            <ion-label position="floating">Position</ion-label>
            <ion-input type="text" formControlName="position"></ion-input>
          </ion-item>

          <ion-item>
            <ion-label>
              <ion-input type="checkbox" slot="start" formControlName="allow_access"></ion-input>
              Allow access?
            </ion-label>
          </ion-item>
          
        </ion-label>
      </ion-item>

      <div *ngIf="!contact">
          
        <ion-item>
          <ion-label position="floating">Name</ion-label>
          <ion-input type="text" formControlName="name"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Position</ion-label>
          <ion-input type="text" formControlName="position"></ion-input>
        </ion-item>

        <ion-item>
          <ion-checkbox slot="start" formControlName="allow_access"></ion-checkbox>
          <ion-label>Allow access?</ion-label>  
        </ion-item>
        
        <ion-item class="field-password">
          <ion-label position="floating" *ngIf="!model.contact_uuid">Password</ion-label>
          <ion-label position="floating" *ngIf="model.contact_uuid">Set new password</ion-label>
          <ion-input type="{{ type }}" formControlName="password"></ion-input>

          <ion-button class="btn-password" fill="clear" (click)="togglePasswordVisibility()">
            <ion-icon *ngIf="type == 'password'" name="eye"></ion-icon>
            <ion-icon *ngIf="type != 'password'" name="eye-off"></ion-icon>
          </ion-button>
        </ion-item>
        
        <ion-item>
          <ion-label>Receive Email?</ion-label>
          <ion-toggle slot="end" formControlName="receive_email"></ion-toggle>
        </ion-item>

        <!--
        <ion-item>
          <ion-label>Receive Notification?</ion-label>
          <ion-toggle slot="end" formControlName="receive_notification"></ion-toggle>
        </ion-item>
        -->
        
      </div>

    </ion-list>

    <ion-card *ngIf="!contact" formArrayName="emails">
      <ion-card-header>
        <ion-card-title>Extra Emails </ion-card-title>
      </ion-card-header>
      <ion-card-content>

        <div *ngFor="let email of emails.controls; let i = index">
          <ion-item >
           <!-- [formGroup]="email"-->
            <ion-input type="email" autocomplete="off1" (ionChange)="onEmailChange($event, i)" formControlName="email_address" inputmode="email"
              enterkeyhint="Next"></ion-input>

            <ion-button fill="clear" slot="end" tabindex="-1" *ngIf="emails.controls.length -1 != i" (click)="removeEmail(i)">
              <img src="assets/images/close.svg">
            </ion-button>

          </ion-item>
        </div>
      </ion-card-content>
    </ion-card>
    
    <ion-card *ngIf="!contact">
      <ion-card-header>
        <ion-card-title>Phones </ion-card-title>
      </ion-card-header>
      <ion-card-content>

        <div *ngFor="let phone of phones.controls; let i = index">
          <ion-item >
<!-- [formGroup]="phone"-->
            <ion-input type="telephone" autocomplete="off1" (ionChange)="onPhoneChange($event, i)" formControlName="phone_number" inputmode="telephone"
                  enterkeyhint="Next"></ion-input>

            <ion-button fill="clear" slot="end" tabindex="-1" *ngIf="phones.controls.length -1 != i" (click)="removePhone(i)">
              <img src="assets/images/close.svg">
            </ion-button>

          </ion-item>
        </div>
      </ion-card-content>
    </ion-card>
  </form>
</ion-content>
