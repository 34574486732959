export const environment = {
  production: true,
  serviceWorker: true,
  envName: 'prod',
  cloudinaryUrl: 'https://res.cloudinary.com/studenthub/image/upload/c_thumb,w_200,h_200,g_face,q_auto/v1596525812/',
  apiEndpoint: 'https://admin.api.studenthub.co/v1',
  permanentBucketUrl: "https://studenthub-uploads.s3.amazonaws.com/",
  candidateAppUrl: "https://student.studenthub.co/",
  employerAppUrl: "https://employer.studenthub.co/",
};
