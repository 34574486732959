<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end">
      <ion-button clear color="primary"
        [disabled]="!form.valid"
        (click)="save()">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" *ngIf="form">
    <ion-list>
        <ion-item>
          <ion-label position="floating">Status Name</ion-label>
          <ion-input type="text" formControlName="status_name"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Status Name - Arabic</ion-label>
          <ion-input type="text" formControlName="status_name_ar"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Is require?</ion-label>
          <ion-toggle formControlName="is_require"></ion-toggle>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Sort Order</ion-label>
          <ion-input type="text" formControlName="sort_order"></ion-input>
        </ion-item>
    </ion-list>
  </form>
</ion-content>
