<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end" *ngIf="!authService.admin_limited_access">
      <ion-button clear color="primary" [disabled]="!form.valid" (click)="save()">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" *ngIf="form">
    <ion-list>

      <div class="logo-wrapper ion-text-center" *ngIf="form">

        <div *ngIf="!progress">
          <img tappable (click)="triggerUpdatePhoto($event)"
            *ngIf="form.controls['logo'].value && form.controls['logo'].value.length > 0"
            src="{{ form.controls['logo_path'].value }}"><br />

          <div tappable (click)="triggerUpdatePhoto($event)" class="no-logo"
            *ngIf="!form.controls['logo'].value || form.controls['logo'].value.length == 0">
            <ion-icon src="assets/images/placeholder.png"></ion-icon>
          </div>

          <ion-button color="dark" id="upload-pic" class="change-logo ion-text-center" #btnChangePhoto
            (click)="updatePhoto($event);">
            <ion-icon src="assets/images/md-cloud-upload.svg"></ion-icon>&nbsp;
            <span *ngIf="model.brand_logo">Replace Logo</span>
            <span *ngIf="!model.brand_logo">Select Logo</span>
          </ion-button>
        </div>

        <input class="upload" type='file' #fileInput (change)="browserUpload($event)" accept=".jpg,.jpeg,.png">
      </div>

      <div class="progress-wrapper" *ngIf="progress">
        <div class="progress-box">

          <h4>Uploading… <span>{{ progress + '%' }}</span></h4>

          <div class="progress-bar">
            <div class="progress" [style.width]="progress + '%'"></div>
          </div>

          <ion-icon *ngIf="currentTarget" tappable src="assets/images/close.svg" (click)="cancelUpload()">
          </ion-icon>
        </div>
      </div>

      <ion-item>
        <ion-label position="floating">Name - English</ion-label>
        <ion-input type="text" formControlName="name_en"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">Name - Arabic</ion-label>
        <ion-input type="text" formControlName="name_ar"></ion-input>
      </ion-item>

    </ion-list>
  </form>
</ion-content>
