import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, empty, throwError } from 'rxjs';
import { catchError, take, map, retryWhen } from 'rxjs/operators';
import { genericRetryStrategy } from '../../util/genericRetryStrategy';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
//services
import { AuthService } from '../auth.service';
import { EventService } from '../event.service';


/**
 * Handles all Authorized HTTP functions with Bearer Token
 */
@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {

  constructor(
    public _http: HttpClient,
    public _auth: AuthService,
    public eventService: EventService
  ) { }

  /**
   * Requests via GET verb
   * @param {string} endpointUrl
   * @param {string} filename
   * @returns {Observable<any>}
   */
  downloadTextFile(endpointUrl: string): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;
    const bearerToken = this._auth.getAccessToken();
    return this._http.get(url, {
      responseType: 'blob',
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        "Currency": this._auth.currency_pref || "KWD",
        'Authorization': 'Bearer ' + bearerToken
      })
    }).pipe(
      // retryWhen(genericRetryStrategy()),
      catchError((err) => {
        return this._handleError(err);
      }),
      map((response) => { // download file        
        var filename = response.headers.get('filename');
        var blob = new Blob([response.body], { type: 'text/plain' });
        //file name to dowanload/generate invoice 
        saveAs(blob, filename);
      })
    );
  }

  /**
   * Requests via GET verb
   * @param {string} endpointUrl
   * @param {number} invoice_id
   * @returns {Observable<any>}
   */
  excelget(endpointUrl: string, filename: string): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;
    const bearerToken = this._auth.getAccessToken();
    return this._http.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        "Currency": this._auth.currency_pref || "KWD",
        'Authorization': 'Bearer ' + bearerToken
      })
    }).pipe(
      //retryWhen(genericRetryStrategy()),
      catchError((err) => {
        return this._handleError(err);
      }),
      map((response) => { // download file
        var blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //file name to dowanload/generate invoice 
        saveAs(blob, filename);
      })
    );
  }

  /**
   * Requests via PDF GET verb
   * @param {string} endpointUrl
   * @param {string} filename
   * @returns {Observable<any>}
   */
  pdfget(endpointUrl: string, filename: string): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;
    const bearerToken = this._auth.getAccessToken();
    return this._http.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        "Currency": this._auth.currency_pref || "KWD",
        'Authorization': 'Bearer ' + bearerToken
      })
    }).pipe(
      map((response) => { // download file
        var blob = new Blob([response], { type: 'application/pdf' });
        //file name to dowanload/generate invoice 
        saveAs(blob, filename);
      })
    );
  }

  /**
   * upload file method
   * @param endpointUrl
   * @param formData
   */
  uploadFile(endpointUrl, formData, retryStrategy = { 
    maxRetryAttempts: 0,
    scalingDuration: 1000,
    excludedStatusCodes: [400, 401, 404, 500] 
  }): Observable<any> {

    const url = environment.apiEndpoint + endpointUrl;

    // Get Bearer Token from Auth Service
    const bearerToken = this._auth.getAccessToken();

    return this._http.post(
      url,
      formData,
      {
        headers: new HttpHeaders({ 
          Accept: 'application/json', 
          enctype: 'multipart/form-data', 
          Authorization: 'Bearer ' + bearerToken 
        })
      }
    )
      .pipe(
        retryWhen(genericRetryStrategy(retryStrategy)),
        catchError((err) => {
          return this._handleError(err);
        }),
        take(1),
        map((res) => res)
      );
  }

  /**
   * Requests via GET verb
   * @param {string} endpointUrl
   * @param {string} withHeader
   * @returns {Observable<any>}
   */
  get(endpointUrl: string, withHeader: boolean = false, retryStrategy = { 
    maxRetryAttempts: 1,
    scalingDuration: 1000,
    excludedStatusCodes: [400, 401, 404, 500] 
  }): Observable<any> {

    const url = environment.apiEndpoint + endpointUrl;

    let response = this._http.get(url, { headers: this._buildAuthHeaders(), observe: 'response' })
      .pipe(
        retryWhen(genericRetryStrategy(retryStrategy)),
        catchError((err) => this._handleError(err)),
        take(1)
      );

    if (!withHeader) {
      return response.pipe(map((res) => {
        return res.body
      }));
    }

    return response;
  }

  /**
   * Requests via POST verb
   * @param endpointUrl
   * @param params
   * @param withHeader
   */
  post(endpointUrl: string, params: any, withHeader: boolean = false, retryStrategy = { 
    maxRetryAttempts: 0,
    scalingDuration: 1000,
    excludedStatusCodes: [400, 401, 404, 500] 
  }): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;

    let response = this._http.post(url, JSON.stringify(params), { headers: this._buildAuthHeaders(), observe: 'response' })
      .pipe(
        retryWhen(genericRetryStrategy(retryStrategy)),
        catchError((err) => this._handleError(err)),
        take(1)
      );

    if (!withHeader) {
      return response.pipe(map((res) => { return res.body }));
    }
    return response.pipe(map((res) => { return res }));
  }

  /**
   * Requests via PATCH verb
   * @param {string} endpointUrl
   * @param {*} params
   * @returns {Observable<any>}
   */
  patch(endpointUrl: string, params: any, retryStrategy = { 
    maxRetryAttempts: 1,
    scalingDuration: 1000,
    excludedStatusCodes: [400, 401, 404, 500] 
  }): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;

    return this._http.patch(url, JSON.stringify(params), { headers: this._buildAuthHeaders() })
      .pipe(
        retryWhen(genericRetryStrategy(retryStrategy)),
        catchError((err) => this._handleError(err)),
        take(1),
        map((res) => { return res; })
      );
  }

  /**
   * Requests via DELETE verb. Params should be a part of the url string 
   * similar to get requests.
   * @param {string} endpointUrl
   * @returns {Observable<any>}
   */
  delete(endpointUrl: string, retryStrategy = { 
    maxRetryAttempts: 0,
    scalingDuration: 1000,
    excludedStatusCodes: [400, 401, 404, 500] 
  }): Observable<any> {
    const url = environment.apiEndpoint + endpointUrl;

    return this._http.delete(url, { headers: this._buildAuthHeaders() })
      .pipe(
        retryWhen(genericRetryStrategy(retryStrategy)),
        catchError((err) => this._handleError(err)),
        take(1),
        map((res) => { return res })
      );
  }

  /**
   * Build the Auth Headers for All Verb Requests
   * @returns {HttpHeaders}
   */
  public _buildAuthHeaders() {
    // Get Bearer Token from Auth Service

    const bearerToken = this._auth.getAccessToken();

    // Build Headers with Bearer Token
    return new HttpHeaders({
      "Authorization": "Bearer " + bearerToken,
      "Content-Type": "application/json",
      "Currency": this._auth.currency_pref || "KWD"
    });
  }

  /**
   * Handles Caught Errors from All Authorized Requests Made to Server
   * @returns {Observable} 
   */
  public _handleError(error: any): Observable<any> {

    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';

    // Handle Bad Requests
    // This error usually appears when agent attempts to handle an 
    // account that he's been removed from assigning
    if (error.status === 400) {
      //this.eventService.agentRemoved$.next({});
      return empty();
    }

    // Handle No Internet Connection Error

    if (error.status == 0 || error.status == 504) {
      this.eventService.internetOffline$.next({});
      //this._auth.logout("Unable to connect to Pogi servers. Please check your internet connection.");
      return empty();
    }

    if (!navigator.onLine) {
      this.eventService.internetOffline$.next({});
      return empty();
    }

    // Handle Expired Session Error
    if (error.status === 401) {
      this._auth.logout('Session expired, please log back in.');
      return empty();
    }

    // Handle internal server error - 500  
    if (error.status === 500) {
      this.eventService.error500$.next({
        message: error.message
      });
      return empty();
    }

    // Handle page not found - 404 error 
    if (error.status === 404) {
      this.eventService.error404$.next({});
      return empty();
    }

    console.error(JSON.stringify(error));

    return throwError(errMsg);
  }
}
