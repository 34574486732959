export class Inspector {
    inspector_uuid: number;
    inspector_name: string;
    inspector_email: string;
    inspector_password_hash: string;
    inspector_status: number;
    inspector_deleted: string;
    inspector_created_at: string;
    inspector_updated_at: string;
}
